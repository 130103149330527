.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.cus-image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px; /* Adjust the margin as needed */
}

img {
  max-width: 60%;
  max-height: 60%;
  border-radius: 8px; /* Add rounded corners if desired */
}

form {
  max-width: 500px;
  border-radius: 5px;
  padding: 30px;
  background-color: rgb(171, 214, 251);
  margin: 50px 0;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

/* Additional styling for form elements as needed */
