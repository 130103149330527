.categories{
    max-width: 700px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 30px auto;
}
.categories form{
    width: 290px;
    margin-bottom: 20px;
    align-self: center;
}
.categories label{
    display: block;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 10px;
}
.categories input, button{
    height: 35px;
    border: none;
    outline: none;
    border-bottom: 1px solid #555;
}
.categories input{
    width: 210px;
}
.categories button{
    width: 70px;
    background: #555;
    border-radius: 9px;
    color: white;
    margin-left: 10px;
}
.categories .row{
   max-width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-left: 145px;
    margin-bottom: 10px;
    border: 1px solid #000000;
}