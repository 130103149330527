.history-page {
    color: #f0f8ff; /* Alice Blue */
    overflow-x: auto;
}

.history-page h2 {
    color: #8b008b; /* DarkMagenta */
    text-align: center;
    margin: 20px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-size: 3rem; /* Larger font size for emphasis */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Text shadow for emphasis */
}

.history-page h4 {
    color: #8a2be2; /* BlueViolet */
    text-align: center;
    margin: 20px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-size: 2rem; /* Larger font size for emphasis */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); /* Text shadow for emphasis */
}

.history-page table {
    color: #f0f8ff; /* Alice Blue */
    margin: auto;
    width: 100%;
    margin-top: 10px; /* Adjusted margin top */
}

.history-page table,
th,
tr,
td {
    color: #f0f8ff; /* Alice Blue */
    border: 1px solid #333232;
    border-collapse: collapse;
}

th,
td {
    color: #000; /* Black */
    text-align: center;
    padding: 15px; /* Increased padding */
    text-transform: capitalize;
}

table a {
    color: #03a5ce; /* DodgerBlue */
    text-decoration: none; /* Remove underline */
    font-weight: bold; /* Bold font for emphasis */
}

.history-page img {
    width: 80px; /* Increased image width */
    height: 120px; /* Increased image height */
    object-fit: cover;
    border-radius: 10px; /* Added border-radius for rounded images */
}

.earnings-table h3 {
    margin-top: 10px;
    margin-bottom: 8px;
    text-align: center;
    color: #8a2be2; /* BlueViolet */
    font-size: 2.5rem; /* Larger font size for emphasis */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); /* Text shadow for emphasis */
}
.earnings-table {
    margin-top: 30px; /* Increased margin top */
}
