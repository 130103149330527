.detail {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 50px;
  font-size: 1.5rem;
}

.detail img {
  max-width: 100%;
  margin: 20px;
  height: 450px;
  object-fit: cover;
  display: block;
}

.box-detail {
  max-width: 500px;
  width: 100%;
  margin: 20px;
  background-color: #f7f7f7; /* Funky background color */
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Add some padding */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.box-detail .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box-detail h2 {
  text-transform: uppercase;
  color: #ff6f61; /* Funky text color */
  letter-spacing: 2px;
  font-weight: bold;
  font-size: 2.5rem; /* Increase font size for emphasis */
  margin-bottom: 10px;
}

.box-detail p {
  line-height: 1.6;
  margin: 10px 0;
  opacity: 0.8;
  color: #3b3b3b; /* Dark text color */
}

.box-detail span {
  color: #ff6f61; /* Funky color for spans */
}

.box-detail .cart {
  background: #333;
  color: white;
  margin-top: 15px;
  padding: 15px 30px; /* Larger padding for a bolder button */
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: none;
  cursor: pointer;
  font-size: 1.4rem; /* Larger font size for emphasis */
  border-radius: 5px; /* Add some rounded corners to the button */
  transition: background 0.3s ease; /* Smooth transition on hover */
}

.box-detail .cart:hover {
  background: #ff6f61; /* Change background color on hover */
}

h2 {
  margin-bottom: 20px;
  font-size: 3rem; /* Increase font size for emphasis */
  color: #D0E6F0;
}

.section-heading {
  margin-top: 20px;
}

.section-heading h3 {
  font-size: 2rem; /* Increase font size for emphasis */
  color: #ff6f61; /* Funky color for section headings */
  margin-bottom: 10px;
}
