.products{
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    justify-items: center;
    margin: 20px 0;
    
}

/* ----------- Product Item ----------------- */
@import url("../utils/productItem/productItem.css");

.delete-all{
    text-align: right;
    margin: 20px;
}
.delete-all input{
    height: 25px;
    width: 25px;
    transform: translateY(5px);
    margin: 0 15px;
}
.delete-all span{
    text-transform: uppercase;
    color: rgb(3, 165, 206);
    letter-spacing: 1.3px;
}
.delete-all button{
    border: 1px solid crimson;
    padding: 10px 25px;
    color: crimson;
    text-transform: uppercase;
}

/* ------------ Filters Menu ----------------- */
.filter_menu{
    width: 100%;
    min-height: 40px;
    font-size: 18px;
    overflow: hidden;
    display: flex;
    background: transparent;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 15px 0;
    margin-left: 2px;
}
.filter_menu select, input{
    border: 2px solid #7d757d;
    
    font-size: 15px;
    margin-left: 3px;
    font-family:sans-serif;
    text-align: center;
    outline: none;
    height: 30px;
    padding: 0 5px;
}
.filter_menu input{
    flex: 1;
    margin: 0 10px;
}

/* -------------------- Load More --------------- */
.load_more{
    text-align: center;
}
.load_more button{
    padding: 10px 25px;
    margin-bottom: 20px;
    background: rgb(128, 0, 4);
    color: white;
    font-size: 15px;
    font-family:sans-serif;
    border: 1px solid #555;
    border-radius: 9px;
    text-transform: capitalize;
}