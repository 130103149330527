.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-container{      
    max-width: 500px;
    flex:1;
    border-radius: 5px;
    padding: 30px;
    background-color: rgb(171, 214, 251);
    margin: 50px 20px;
    
}
.image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}


img {
    max-width: 100px;
    max-height: 100px;
    border-radius: 8px;
}

.row {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.login-page h2{
    text-transform: uppercase;
    letter-spacing: 3px;
    color: rgba(76, 9, 211, 0.705);
}
.login-page form input,
.login-page form button{
    width: 100%;
    height: 40px;
    margin: 10px 0;
    padding: 0 5px;
    outline: rgb(97, 1, 145);
}
.login-page form .row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.login-page form button{
    width: 150px;
    background: rgb(111, 3, 206);
    color: white;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 5px 5px;
}
.login-page form a{
    color: purple;
    letter-spacing: 1px;
    text-transform: uppercase;
}