.product_card {
    background-color: white;
    max-width: 100%;
    display: flex; /* Changed to flex display for horizontal layout */
    overflow: hidden;
    height: auto;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Updated box-shadow for a subtle effect */
    margin: 10px 0;
    margin-left: 2rem;
    margin-right: 2rem;
    position: relative;
    border-radius: 10px;
    transition: box-shadow 0.3s ease; /* Added transition for a smooth effect on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.product_card:hover {
    box-shadow: 0 8px 46px rgba(0, 0, 0, 0.2); /* Updated box-shadow on hover for a more pronounced effect */
}


.product_card img {
    margin-right: 1rem; /* Adjusted margin */
    width: 150px; /* Adjusted width */
    height: auto;
    object-fit: cover;
}

.product_box {
    flex: 1; /* Allow the content to take remaining space */
}

.product_box h2 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-transform: capitalize;
    cursor: pointer;
    color: #323232;
}

.product_card h4 {
    color: crimson;
}

.product_box p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    height: 40px;
    overflow: hidden;
    color: #323232;
    margin-right: 1rem;
}

.row_btn {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    /* Align buttons to the end */
}

.row_btn a {
    text-align: center;
    text-transform: uppercase;
    color: white;
    font-weight: 600;
    letter-spacing: 2px;
    padding: 10px;
    border-radius: 15px;
    margin-left: 10px; /* Adjusted margin */
}

#btn_buy {
    background: rgb(50, 162, 50);
    margin: 0.5rem;
}

#btn_view {
    background: rgb(239, 120, 124);
    margin: 0.5rem;
}

.product_card input {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
}
