.cart{
    position: relative;
    border-radius: 10px;
    transform: scaleY(0.98);
}
.amount span{
    color: rgb(0, 0, 0);
    padding: 0 20px;
}
.amount button{
    width: 40px;
    height: 40px;
    border-radius: 15px;
    background-color: black;
    color: rgb(246, 243, 243);
    font-size: 20px;
    border: 1px solid #777777;
    
}
.delete{
    position: absolute;
    top:0;
    right: 5px;
    color: crimson;
    font-weight: 900;
    cursor: pointer;
}
.total{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}
.total h3{
    margin-left: 20px;
    color: #3934db;
}

.payment {
    background: #3498db;
  background-image: -webkit-linear-gradient(top, #3498db, #2980b9);
  background-image: -moz-linear-gradient(top, #3498db, #2980b9);
  background-image: -ms-linear-gradient(top, #3498db, #2980b9);
  background-image: -o-linear-gradient(top, #3498db, #2980b9);
  background-image: linear-gradient(to bottom, #3498db, #2980b9);
  -webkit-border-radius: 28;
  -moz-border-radius: 28;
  border-radius: 28px;
  -webkit-box-shadow: 0px 1px 3px #666666;
  -moz-box-shadow: 0px 1px 3px #666666;
  box-shadow: 0px 1px 3px #666666;
  font-family: Arial;
  margin-right: 20px;
  color: #ffffff;
  font-size: 18px;
  padding: 11px 20px 10px 20px;
  text-decoration: none;
}

.payment:hover{
    background: #f59920;
  background-image: -webkit-linear-gradient(top, #f59920, #f2b809);
  background-image: -moz-linear-gradient(top, #f59920, #f2b809);
  background-image: -ms-linear-gradient(top, #f59920, #f2b809);
  background-image: -o-linear-gradient(top, #f59920, #f2b809);
  background-image: linear-gradient(to bottom, #f59920, #f2b809);
  text-decoration: none;
}