

* {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App{
  max-width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0 0px;
  box-shadow: 0 0 35px #eee;
  background-color: rgb(215, 233, 249);
}
a{
  text-decoration: none;
}
ul li {
  list-style: none;
}
button{
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}

/* ---------------- Header ------------ */
@import url("./components/headers/header.css");

/* ------------- Products -------------- */

@import url("./components/mainpages/products/products.css");

/* ---------------- Detail Product --------------- */
@import url("./components/mainpages/detailProduct/detailProduct.css");

/* ------------- Login----------------- */
@import url("./components/mainpages/auth/login.css");
/* ------------- Register ----------------- */
@import url("./components/mainpages/auth/register.css");


/* ------------ Cart ------------------- */
@import url("./components/mainpages/cart/cart.css");

/* --------------- History ------------------- */
@import url("./components/mainpages/history/history.css");

/* ------------------ Categories ------------------ */
@import url("./components/mainpages/categories/categories.css");

/* ------------------- Create Product -------------- */
@import url("./components/mainpages/createProduct/createProduct.css");

/* ----------------- Responsive ------------------ */
@media (max-width: 820px){
  header .logo{
    min-width: 115px;
    flex: none;
  }
  header ul{
   padding: 10px 0;
  }
}
@media (max-width: 720px){
  header ul{
   position: fixed;
   top:0;
   left: -100%;
   width: 100%;
   height: 100vh;
   background: white;
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   align-items: center;
   opacity: 0.98;
   z-index: 99;
   transition: 0.5s ease-in;
  }
  header .menu{
    display: block;
    cursor: pointer;
  }
  header ul li .menu{
    position: absolute;
    top:20px;
    right: 20px;
  }
  header{
    justify-content: space-between;
  }
}

@media (max-width: 570px){
  .filter_menu .row span{
    display: none;
  }
}

@media (max-width: 500px){
  .App{
    padding: 0 10px;
  }
  .detail{
    font-size: 100%;
    padding: 0;
  }
  .filter_menu input{
    margin: 0;
  }
  .filter_menu .sort{
    flex: 1;
  }
  .filter_menu .sort select{
    width: 100%;
    min-width: 290px;
    margin: 5px 0;
  }
  .login-page{
    padding: 30px 15px;
  }
  .delete-all{
    margin: 20px 0;
  }
  .create_product .upload{
    margin: 20px 0;
  }
}
